<template>
  <div class="custom-card-box death-box">
    <span class="title">逝者信息</span>
    <a-row :gutter="12" class="death-box-row">
      <a-col span="12" class="death-box-col">
        <div class="death-content">
          <div>
            <Carousel v-if="data.images && data.images.length > 0">
              <div v-for="(item, index) in data.images" :key="index" style="text-align: center">
                <img
                  alt="img"
                  :src="item.url"
                  width="500"
                  height="700"
                >
              </div>
            </Carousel>
            <img
              v-show="!data.images || data.images.length === 0"
              alt="img"
              src="/temp/death.png"
            >
          </div>

          <div class="death-name">
            <span>{{ data.name }}</span>
          </div>
        </div>
      </a-col>

      <a-col span="12" class="death-box-col">
        <div class="death-life-info">
          <div class="death-life-info-title"><span>生平简介：</span></div>
          <div class="death-life-info-content"><span> {{ data.life_info }}</span></div>
        </div>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import { findServiceOrderDeath } from '@/api/service_order_death'
import { Carousel } from 'ant-design-vue'

export default {
  name: 'ServiceOrderDeathIndex',
  components: {
    Carousel
  },
  data() {
    return {
      data: {}
    }
  },
  computed: {
    hallId() {
      return parseInt(this.$route.params.id)
    }
  },
  created() {
    this.fetchData()
  },
  methods: {
    fetchData() {
      findServiceOrderDeath({ hall_id: this.hallId }).then((res) => {
        if (res.code === 0) {
          this.data = res.data
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.death-box {
  height: 100%;

  .title {
    font-size: 20px;
  }

  .death-box-row {
    height: 100%;
  }

  .death-box-col {
    height: 100%;

    .death-content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      //align-items: center;
      text-align: center;
      height: 100%;

      img {
        width: 300px;
        height: 350px;
      }

      .death-name {
        display: flex;
        justify-content: center;
        margin-top: 20px;
        width: 100%;
        font-size: 24px;
        color: #ffd794ff;
        background-color: #37543e;
      }
    }

    .death-life-info {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 20px;
      height: 70%;

      .death-life-info-title {
        font-size: 18px;
      }

      .death-life-info-content {
        color: #c7ccc9;
      }
    }
  }
}

h1 {
  color: @white;
}
/deep/ .ant-carousel .slick-slide img {
  display: inline;
}
</style>

